import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { CategoryButtons, ContentWrapper, RowLayout } from "../components"

const ContributersPageTemplate = ({ data }) => {
  const { title, subtitle, categories } = data.markdownRemark.frontmatter

  const [selectedCategory, setSelectedCategory] = useState("")

  const filteredCategories =
    selectedCategory === ""
      ? categories
      : categories.filter(item => item.title === selectedCategory)

  return (
    <ContentWrapper title={title} subtitle={subtitle}>
      <CategoryButtons
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <RowLayout categories={filteredCategories} />
    </ContentWrapper>
  )
}

ContributersPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContributersPageTemplate

export const pageFragment = graphql`
  fragment ContributorsTeamCommitteePages on MarkdownRemarkFrontmatter {
    title
    subtitle
    categories {
      title
      subtitle
      people {
        name
        job_title
        description
        linked_in
        twitter
        image {
          childImageSharp {
            gatsbyImageData(width: 85)
          }
        }
      }
    }
    banner {
      title
      subtitle
      button {
        title
        link
      }
    }
  }
`
